exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-passordbanken-tsx": () => import("./../../../src/pages/passordbanken.tsx" /* webpackChunkName: "component---src-pages-passordbanken-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-signaturemanager-tsx": () => import("./../../../src/pages/signaturemanager.tsx" /* webpackChunkName: "component---src-pages-signaturemanager-tsx" */),
  "component---src-templates-position-tsx": () => import("./../../../src/templates/position.tsx" /* webpackChunkName: "component---src-templates-position-tsx" */),
  "component---src-templates-testimonial-tsx": () => import("./../../../src/templates/testimonial.tsx" /* webpackChunkName: "component---src-templates-testimonial-tsx" */)
}

